<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="作者UCID">
        <el-input v-model="form.artist_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.artist_name"></el-input>
      </el-form-item>
      <el-form-item label="责任商务">
        <el-input v-model="form.business_contact"></el-input>
      </el-form-item>
      <el-form-item label="是否开通小店">
        <el-select v-model="form.open_shop">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="未开通" :value="2"> </el-option>
          <el-option label="已开通" :value="1"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="统计时段">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <div class="range-picker">
          <el-button type="text" size="default" @click="setDateRange(-1)">昨天</el-button>
          <el-button type="text" size="default" @click="setDateRange(7)">近7天</el-button>
          <el-button type="text" size="default" @click="setDateRange(30)">近30天</el-button>
          <el-button type="text" size="default" @click="setDateRange('all')">所有时间</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe @sort-change="handleSortChange">
        <el-table-column label="艺术家信息" prop="artist_ucid"
          ><template slot-scope="scope">
            {{ scope.row.artist_ucid + `(${scope.row.artist_name})` }}
          </template></el-table-column
        >
        <el-table-column
          sortable="custom"
          label="恒境上架总作品数"
          prop="hengjing_work_group_num"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          label="恒境交易笔数"
          prop="hengjing_trade_num"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          label="恒境CNY手续费"
          prop="hengjing_cny_service_fee"
        ></el-table-column>
        <!-- TODO 隐藏部分 -->
        <!-- <el-table-column
          sortable="custom"
          label="恒境BSV手续费"
          prop="hengjing_bsv_service_fee"
        ></el-table-column> -->
        <el-table-column label="是否开店" prop="open_shop">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.open_shop" type="success">已开通</el-tag>
            <el-tag v-else type="info">未开通</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          sortable="custom"
          label="小店上架总作品数"
          prop="shop_work_group_num"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          label="小店交易笔数"
          prop="shop_trade_num"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          label="小店CNY手续费"
          prop="shop_cny_service_fee"
        ></el-table-column>
        <el-table-column
          sortable="custom"
          label="版税收益"
          prop="copyright_income"
        ></el-table-column>
        <el-table-column label="破发数量" prop="po_fa_num"></el-table-column>
        <el-table-column label="新粉丝数" prop="fans_num"></el-table-column>
        <el-table-column label="拉新数" prop="invite_num"></el-table-column>
        <el-table-column label="责任商务" prop="business_contact">
          <template slot-scope="scope">
            <span>{{ scope.row.business_contact || "-" }}</span>
            <el-button
              style="margin-left: 8px"
              type="text"
              size="small"
              @click="handleEditBusinessContact(scope.row)"
            >
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      title="编辑责任商务"
      :visible.sync="editContactVisible"
      width="30%"
      @close="editContactVisible = false"
    >
      <div>
        <el-input v-model="editContactValue" placeholder="请输入责任商务" size="normal" clearable />
      </div>
      <span slot="footer">
        <el-button @click="editContactVisible = false">取消</el-button>
        <el-button type="primary" @click="handleConfirmEditContact">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getArtistList, setArtistManage } from "../../../api/nft";
const dateFormat = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};
const getDateRange = (day) => {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * day);
  end.setTime(end.getTime() - 3600 * 1000 * 24);
  console.log(start, end);
  return [dateFormat(start), dateFormat(end)];
};
export default {
  data() {
    return {
      form: {
        open_shop: 0,
        audit_profile_status: "",
      },
      dateRange: [],
      list: [],
      total: 0,
      pageSize: 20,
      page: 1,
      sortBy: "",
      sortOrder: "",
      artistDialogVisible: false,
      editContactVisible: false,
      editContactValue: "",
      editArtistUcid: "",
    };
  },
  mounted() {
    this.fetchList();
  },
  computed: {
    params() {
      const { artist_ucid, artist_name, open_shop } = this.form;
      let params = {
        artist_ucid: artist_ucid,
        artist_name: artist_name,
        open_shop: open_shop,
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy,
        order_by: this.sortOrder,
        business_contact: this.form.business_contact,
      };
      if (this.setTimeStart(this.dateRange[0])) {
        params.start_at = this.setTimeStart(this.dateRange[0]);
      }
      if (this.setTimeEnd(this.dateRange[0])) {
        params.end_at = this.setTimeEnd(this.dateRange[1]);
      }
      return params;
    },
  },
  methods: {
    setTimeStart(time) {
      return time
        ? dayjs(time)
            .startOf("day")
            .unix()
        : "";
    },
    setTimeEnd(time) {
      return time
        ? dayjs(time)
            .endOf("day")
            .unix()
        : "";
    },
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getArtistList(params);
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {
        open_shop: 0,
        artist_ucid: "",
        artist_name: "",
      };
      this.page = 1;
      this.fetchList();
    },
    setDateRange(day) {
      if (day === -1) {
        let start = dayjs().subtract(2, "day");
        let end = dayjs().subtract(1, "day");
        return (this.dateRange = [dateFormat(start), dateFormat(end)]);
      }
      if (day === "all") {
        return (this.dateRange = []);
      }
      this.dateRange = getDateRange(day);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    async handleConfirmEditContact() {
      try {
        const postData = {
          artist_ucid: this.editArtistUcid,
          business_contact: this.editContactValue,
        };
        await setArtistManage(postData);
        this.editArtistUcid = "";
        this.editContactVisible = false;
        this.editContactValue = "";

        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },
    handleEditBusinessContact(item) {
      this.editContactVisible = true;
      this.editArtistUcid = item.artist_ucid;
      this.editContactValue = item.business_contact;
    },
  },
};
</script>

<style lang="less"></style>
